import { Link, useLocation } from "react-router-dom";
import SVGForPage from "./SVGForPage";

const HelpButton = () => {
  const location = useLocation();
  const isHelpActive = location.pathname.startsWith("/help");

  return (
    <li className="nav-item dropdown">
      <Link
        className={`nav-link dropdown-toggle ${isHelpActive ? "underline-navbar-item" : ""}`}
        to="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={(e) => e.preventDefault()}
      >
        <i className="bi bi-question-circle pe-2"></i>Help
      </Link>
      <ul className="dropdown-menu">
        <li className="nav-item">
          <Link className="dropdown-item" to="/help/new">
            <SVGForPage pageKeyword="help-new" />
            Create New Ticket
          </Link>
        </li>
        <li className="nav-item">
          <Link className="dropdown-item" to="/help/tickets">
            <SVGForPage pageKeyword="help-tickets" />
            My Tickets
          </Link>
        </li>
      </ul>
    </li>
  );
};

export default HelpButton;
