import { useState } from "react";
import { Link } from "react-router-dom";
import { callApi, HttpMethod } from "src/services/apiService";
import Loading from "src/components/Loading";

interface HelpFormData {
  issueType: string;
  subject: string;
  description: string;
}

const defaultFormData: HelpFormData = {
  issueType: "0",
  subject: "",
  description: "",
};

const NewTicket = () => {
  const [formData, setFormData] = useState<HelpFormData>(defaultFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage(null);
    setIsSubmitting(true);

    try {
      const response = await callApi("help/ticket", HttpMethod.POST, formData);
      if (response?.message) {
        setMessage("Ticket created successfully.");
        setFormData(defaultFormData);
      } else {
        setMessage("Failed to create ticket.");
      }
    } catch (error) {
      console.error("Error creating ticket:", error);
      setMessage("An error occurred while creating the ticket.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClear = () => {
    setFormData(defaultFormData);
    setMessage(null);
  };

  return (
    <div className="container-fluid mb-5">
      <h1>New Ticket</h1>

      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-header">Contact Help Desk</div>
          <div className="card-body">
            {isSubmitting && <Loading />}
            {message && <div className="alert alert-info mt-3">{message}</div>}

            <div className="mb-3 row">
              <label
                className="form-label mb-0"
                style={{
                  maxWidth: "160px",
                  minWidth: "120px",
                  whiteSpace: "nowrap",
                }}
              >
                Issue Type <span className="text-danger">*</span>
              </label>
              <div className="col-12 col-sm-8 col-md-9">
                <select
                  name="issueType"
                  className="form-select"
                  value={formData.issueType}
                  onChange={handleChange}
                  required
                >
                  <option value="0">Select one</option>
                  <option value="1">Trouble Logging In</option>
                  <option value="6">Unable to Upload</option>
                  <option value="7">Unable to Download</option>
                  <option value="8">General Issue - Low Priority</option>
                  <option value="9">General Issue - Medium Priority</option>
                  <option value="10">General Issue - High Priority</option>
                  <option value="4">How do I ... Question</option>
                  <option value="5">Feature Request</option>
                  <option value="3">Bug Report</option>
                </select>
              </div>
            </div>

            <div className="mb-3 row">
              <label
                className="form-label mb-0"
                style={{
                  maxWidth: "160px",
                  minWidth: "120px",
                  whiteSpace: "nowrap",
                }}
              >
                Subject <span className="text-danger">*</span>
              </label>
              <div className="col-12 col-sm-8 col-md-9">
                <input
                  type="text"
                  name="subject"
                  className="form-control"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="mb-3 row">
              <label
                className="form-label mb-0"
                style={{
                  maxWidth: "160px",
                  minWidth: "120px",
                  whiteSpace: "nowrap",
                }}
              >
                Description <span className="text-danger">*</span>
              </label>
              <div className="col-12 col-sm-8 col-md-9">
                <textarea
                  name="description"
                  className="form-control"
                  value={formData.description}
                  onChange={handleChange}
                  rows={5}
                  required
                ></textarea>
              </div>
            </div>

            <div className="w-100 text-center">
              <small>
                For faster response before 11:00 a.m. or after 7:30 p.m EST, or
                on weekends, select "General Issue - High Priority".
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-start mt-2 mb-5 ">
          <button
            type="submit"
            className="btn btn-primary me-2"
            disabled={isSubmitting}
          >
            Submit Request
          </button>
          <button
            type="button"
            className="btn btn-secondary d-none"
            onClick={handleClear}
            disabled={isSubmitting}
          >
            Clear
          </button>
          <Link to="/help/tickets" className="btn btn-secondary">
            Return to My Tickets
          </Link>
        </div>
      </form>
    </div>
  );
};

export default NewTicket;
