import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callApi, HttpMethod } from "src/services/apiService";
import Loading from "src/components/Loading";
import SafeHtml from "src/components/SafeHtml";
import Warning from "src/components/Warning";

interface TicketComment {
  id: number;
  body: string;
  authorId: number;
  createdAt: string;
}

interface TicketDetails {
  id: number;
  subject: string;
  description: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  comments: TicketComment[];
}

const TicketDetails = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const [ticket, setTicket] = useState<TicketDetails | null>(null);
  const [newComment, setNewComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState<string>("");
  const [commentError, setCommentError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (ticketId) {
      fetchTicketDetails(ticketId);
    }
    // eslint-disable-next-line
  }, [ticketId]);

  const fetchTicketDetails = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await callApi(`help/ticket/${id}`, HttpMethod.GET);
      if (response) {
        if (response?.type === "FAILED" && response.message) {
          console.log(response.message);
          setShowWarning(response.message);
        } else {
          setTicket(response);
        }
      }
    } catch (error) {
      console.error("Error fetching ticket details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim() || !ticketId) return;

    setIsSubmitting(true);
    setCommentError(null);
    try {
      const response = await callApi(
        `help/ticket/${ticketId}/comment`,
        HttpMethod.POST,
        {
          key: "",
          value: newComment,
        },
      );
      if (response?.type?.toLowerCase().includes("failed")) {
        setCommentError("Failed to save your comment. Please try again.");
      } else {
        setNewComment("");
        await fetchTicketDetails(ticketId);
      }
      setNewComment("");
      await fetchTicketDetails(ticketId);
    } catch (err) {
      console.error("Error submitting comment:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status.toLowerCase()) {
      case "new":
        return "text-bg-primary";
      case "open":
        return "text-bg-warning";
      case "pending":
        return "text-bg-secondary";
      case "on-hold":
        return "text-bg-dark";
      case "solved":
        return "text-bg-success";
      case "closed":
        return "text-bg-danger";
      default:
        return "text-bg-light";
    }
  };

  const canUserComment = (status: string) => {
    const normalized = status.toLowerCase();
    return normalized !== "solved" && normalized !== "on-hold";
  };

  if (isLoading) {
    return <Loading />;
  }

  if (showWarning) {
    return (
      <div className="container-fluid mt-4">
        <Warning message={showWarning} />
        <button
          className="btn btn-secondary mt-3"
          onClick={() => navigate("/help/tickets")}
        >
          Back to Tickets
        </button>
      </div>
    );
  }
  if (!ticket) return null;
  return (
    <div className="container-fluid mb-5">
      <h1>Ticket Details</h1>
      <Warning message={showWarning} />
      <div className="d-flex justify-content-between align-items-center m-2">
        <div>
          <a href="/help/tickets" className="text-decoration-none pe-2">
            My Tickets
          </a>
          &raquo; Ticket #{ticket.id} &raquo;
          <h5 className="d-inline ms-2">{ticket.subject} </h5>
          <span
            className={`badge text-uppercase align-text-top ${getStatusBadgeClass(ticket.status)}`}
          >
            {ticket.status}
          </span>
        </div>
      </div>

      <div className="p-3 rounded border border-dark-subtle shadow bg-light">
        <SafeHtml html={ticket.description} />

        <div
          className="text-end mt-2"
          style={{ fontSize: "0.8rem", color: "#888" }}
        >
          {new Date(ticket.createdAt).toLocaleString()}
        </div>
      </div>

      {ticket.comments.length === 0 ? (
        <p>Awaiting response from support.</p>
      ) : (
        <div className="mt-3 d-flex flex-column gap-3">
          {ticket.comments.slice(1).map((comment) => {
            const isSupport = comment.authorId !== ticket.comments[0].authorId;

            return (
              <div
                key={comment.id}
                className={`p-3 rounded border border-dark-subtle shadow ${
                  isSupport ? "bg-primary bg-opacity-10" : "bg-light"
                }`}
              >
                <div className="mb-1 fw-semibold">
                  {isSupport ? "Support" : "You"}
                </div>

                <SafeHtml html={comment.body} />

                <div
                  className="text-end mt-2"
                  style={{ fontSize: "0.8rem", color: "#888" }}
                >
                  {new Date(comment.createdAt).toLocaleString()}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {canUserComment(ticket.status) ? (
        <>
          <hr />
          <h4 className="mt-4">Add a Comment</h4>
          <Warning message={commentError ?? ""} />

          <textarea
            className="form-control mb-2"
            rows={4}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Type your comment here..."
          />
          <button
            className="btn btn-primary"
            onClick={handleAddComment}
            disabled={isSubmitting || !newComment.trim()}
          >
            {isSubmitting ? "Submitting..." : "Submit Comment"}
          </button>
        </>
      ) : (
        <>
          <hr />
          <div className="alert alert-secondary mt-4">
            Comments cannot be added to tickets with status{" "}
            <strong>{ticket.status}</strong>.
          </div>
        </>
      )}
      <button
        className="btn btn-secondary ms-2"
        onClick={() => navigate("/help/tickets")}
      >
        Return to My Tickets
      </button>
    </div>
  );
};

export default TicketDetails;
