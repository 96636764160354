import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import SaveInProgress from "src/components/SaveInProgress";
import { IClient, IClientAccess } from "src/interfaces/Admin/IClientAccess";
import Warning from "src/components/Warning";
import SafeHtml from "src/components/SafeHtml";

interface IExtendedClient extends IClient {
  selected: boolean;
  initiallyDisabled: boolean;
}

const ClientAccess = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [takeNewClients, setTakeNewClients] = useState(false);
  const [formData, setFormData] = useState<IClientAccess>();
  const [clientData, setClientData] = useState<IExtendedClient[]>([]);
  const { register, handleSubmit } = useForm<IClientAccess>({
    mode: "onChange",
  });

  useEffect(() => {
    const fetchClientAccess = async () => {
      setIsLoading(true);
      try {
        const apiUrl = "admin/clientaccess";
        const response = await callApi(apiUrl, HttpMethod.GET);
        setFormData(response);
        setTakeNewClients(!response.accounts.agencyTakingNewClients);
        const clientsWithSelection: IExtendedClient[] = JSON.parse(
          response.clients,
        ).map((client: IClient) => ({
          ...client,
          selected: false,
          initiallyDisabled: client.usageBilledTo !== client.clientAcctId,
        }));
        setClientData(clientsWithSelection);
      } catch (error) {
        console.error("Failed to fetch client access info:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchClientAccess();
  }, []);

  const toggleClientSelection = (id: string) => {
    const updatedClients = clientData.map(
      (client) =>
        client.id.toString() === id
          ? { ...client, selected: !client.selected }
          : client, // Ensure id is treated as a string
    );
    setClientData(updatedClients);
    checkSelectAll(updatedClients);
  };
  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    const updatedClients = clientData.map((client) => ({
      ...client,
      selected: newSelectAll,
    }));
    setClientData(updatedClients);
    setSelectAll(newSelectAll);
  };

  const checkSelectAll = (clients: IExtendedClient[]) => {
    const allSelected = clients.every((client) => client.selected);
    setSelectAll(allSelected);
  };

  const submitRemoveAccessToSelectedClients = async () => {
    const selectedClientIds = clientData
      .filter((client) => client.selected)
      .map((client) => client.clientAcctId.toString());
    //console.log("Selected client IDs:", selectedClientIds);
    setSaveInProgress(true);
    try {
      await callApi(
        "admin/clientaccess/Remove",
        HttpMethod.POST,
        selectedClientIds,
      );
      console.log("Client options saved successfully");
      setSaveInProgress(false);
    } catch (error) {
      console.error("Failed to save client options:", error);
      setWarningMessage("Failed to save client options");
      setSaveInProgress(false);
    }
  };

  const handleUpdateSettings = async (formData: IClientAccess) => {
    const accountsPayload = {
      ...formData.accounts,
      agencyTakingNewClients: !takeNewClients,
    };

    const payload = {
      accounts: accountsPayload,
      clients: clientData,
    };
    //console.log("Payload:", payload);
    setSaveInProgress(true);
    try {
      await callApi("admin/clientaccess/Save", HttpMethod.POST, payload);
      console.log("Client options saved successfully");
      setSaveInProgress(false);
    } catch (error) {
      console.error("Failed to save client options:", error);
      setWarningMessage("Failed to save client options");
      setSaveInProgress(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <h6>Client Account Access Options</h6>
      {formData && (
        <>
          <p>
            <SafeHtml html={formData.content} />
          </p>
          <form
            onSubmit={handleSubmit(handleUpdateSettings)}
            className="grid grid-cols-2 gap-4"
          >
            <div>
              <input
                type="checkbox"
                className="me-2"
                checked={takeNewClients}
                onChange={(e) => setTakeNewClients(e.target.checked)}
              />
              I am NOT taking any new Clients
            </div>

            <div className="card">
              <div className="card-body">
                <div className="fw-bold">
                  <small>
                    When you scrub lists in the Client's Project or
                    Sub-Projects, the 'records scrubbed' count/billing can
                    either go against the Client's account or this Agency
                    account. This is configured by the Client based on the
                    options you enable below:
                  </small>
                </div>
                <input
                  type="radio"
                  value="3"
                  className="me-2"
                  checked={formData.accounts.agencyClientBilling === 3}
                  {...register("accounts.agencyClientBilling")}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      accounts: {
                        ...formData.accounts,
                        agencyClientBilling: 3,
                      },
                    })
                  }
                />
                Clients can choose either billing option
                <br />
                <input
                  type="radio"
                  value="1"
                  className="me-2"
                  checked={
                    formData.accounts.agencyClientBilling === 1 ||
                    formData.accounts.agencyClientBilling === 2
                  }
                  {...register("accounts.agencyClientBilling")}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      accounts: {
                        ...formData.accounts,
                        agencyClientBilling: 1,
                      },
                    })
                  }
                />
                My list scrubbing in a Client's Project is always billed under:
                <select
                  {...register("accounts.agencyClientBilling")}
                  value={formData.accounts.agencyClientBilling}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      accounts: {
                        ...formData.accounts,
                        agencyClientBilling: parseInt(e.target.value),
                      },
                    })
                  }
                >
                  <option value="1">Client's Account</option>
                  <option value="2">My Agency Account</option>
                </select>
              </div>
              <div className="card-footer">
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  onClick={() => handleSubmit(handleUpdateSettings)()}
                >
                  <i className="bi bi-floppy me-2"></i>Update Client Options
                </button>
              </div>
            </div>

            <div className="col-span-2 mt-2 fw-bold"></div>
            <div className="card">
              <div className="card-header">Current Client Account Access</div>
              <div className="card-body">
                <div className="fw-bold fs-6">
                  Note that you can change the billing to go under your Agency
                  account but you CANNOT change it back to Client's Account -
                  only Clients can do that and only if your billing options
                  configured above allow them!
                </div>
                <div>
                  <table className="100vh">
                    <thead>
                      <tr>
                        <th>
                          <button type="button" onClick={toggleSelectAll}>
                            {selectAll ? "Unselect All" : "Select All"}
                          </button>
                        </th>
                        <th>Client Account Code</th>
                        <th>Client Account Name</th>
                        <th>
                          Show Client's Master Project Under this Name in My
                          Projects
                        </th>
                        <th>
                          My List Scrubbing in this Client's Projects is Billed
                          Under
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientData.map((client, index) => (
                        <tr key={client.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={client.selected}
                              onChange={() =>
                                toggleClientSelection(client.id.toString())
                              }
                            />
                          </td>
                          <td>{client.clientAcctId}</td>
                          <td>{client.clientAcctName}</td>
                          <td className="text-center">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={client.showAs ?? ""}
                              onChange={(e) => {
                                const updatedClients = [...clientData];
                                updatedClients[index] = {
                                  ...updatedClients[index],
                                  showAs: e.target.value,
                                };
                                setClientData(updatedClients);
                              }}
                            />
                          </td>
                          <td>
                            <select
                              defaultValue={client.usageBilledTo}
                              disabled={client.initiallyDisabled}
                              onChange={(e) => {
                                if (
                                  client.usageBilledTo ===
                                    client.clientAcctId &&
                                  e.target.value === formData.myAcctId
                                ) {
                                  alert("Are you sure you want to change?");
                                }

                                const updatedClients = [...clientData];
                                updatedClients[index] = {
                                  ...updatedClients[index],
                                  usageBilledTo: e.target.value,
                                };
                                setClientData(updatedClients);
                              }}
                            >
                              <option value={client.clientAcctId}>
                                Client's Account
                              </option>
                              <option value={formData.myAcctId}>
                                My Agency Account
                              </option>
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  onClick={submitRemoveAccessToSelectedClients}
                >
                  Remove Access to Selected Client Accounts
                </button>
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  onClick={() => handleSubmit(handleUpdateSettings)()}
                >
                  <i className="bi bi-floppy me-2"></i>Update Settings
                </button>

                <SaveInProgress isVisible={saveInProgress} />
                <Warning message={warningMessage} />
              </div>
            </div>

            <div className="col-span-2 mt-2 mt-5"></div>
          </form>
        </>
      )}
    </>
  );
};

export default ClientAccess;
