import * as React from "react";
import DOMPurify from "dompurify";

interface SafeHtmlProps {
  html: string | null;
}

const SafeHtml: React.FC<SafeHtmlProps> = ({ html }) => {
  const cleanHtml = html ? DOMPurify.sanitize(html) : "";
  const formattedHtml = cleanHtml.replace(/(?:\r\n|\r|\n)/g, "<br />");
  return <div dangerouslySetInnerHTML={{ __html: formattedHtml }} />;
};

export default SafeHtml;
