import { Table } from "src/components/Table";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import { useLayoutContext } from "src/contexts/LayoutContext";
import { Grid } from "react-loader-spinner";
import regionsFederalData from "src/data/regionsFederal.json";
import regionsUSAData from "src/data/regionsUSA.json";
import styles1 from "./Index.module.css";

import styles from "src/pages/ComplianceGuide/RegulatoryGuide/Guide.module.css";
import State from "src/components/ComplianceGuide/State";

type ChartOption = {
  label: string;
  value: string;
};

type ColumnHeader = {
  label: string;
  field: string;
};

type Region = {
  state: string;
  stateName: string;
};

export const Index = () => {
  const [selectedTopicTitle, setSelectedTopicTitle] = useState("");
  const [selectedTopicView, setSelectedTopicView] = useState("");
  const [chartOptions, setChartOptions] = useState<ChartOption[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableHeader, setTableHeader] = useState<ColumnHeader[]>([]);
  const [hideTableHeader, setHideTableHeader] = useState(false);
  const [showNPOHeader, setShowNPOHeader] = useState(false);
  const [regions, setRegions] = useState<Region[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedRegions, setSelectedRegions] = useState<
    { state: string; stateName: string }[]
  >([]);
  const [
    isCheckedIncludePreviousYearHolidays,
    setIsCheckedIncludePreviousYearHolidays,
  ] = useState(false);

  const getColumnHeaders = useCallback((topic: string): ColumnHeader[] => {
    let columnHeaders: ColumnHeader[] = [];
    switch (topic) {
      case "LegalCommRegSummaries":
        columnHeaders = [
          { label: "Jurisdiction", field: "jurisdiction" },
          { label: "Requires Registration", field: "requiresRegistration" },
          { label: "Inbound Included", field: "inboundIncluded" },
          { label: "Exemptions", field: "exemptions" },
          { label: "Contact", field: "contact" },
          { label: "Citation", field: "citation" },
        ];
        setHideTableHeader(false);
        setShowNPOHeader(false);
        setSelectedTopicTitle("Commercial Registration");
        return columnHeaders;
      case "LegalRegFeeBondSummaries":
        columnHeaders = [
          { label: "Jurisdiction", field: "stateName" },
          { label: "Registration Fee", field: "regFee" },
          { label: "Registration Bond", field: "regBond" },
          { label: "Additional Fees", field: "regAdditionalFees" },
        ];
        setHideTableHeader(false);
        setShowNPOHeader(false);
        setSelectedTopicTitle("Registration Fees and Bonds");
        return columnHeaders;
      case "LegalDncSummaries":
        columnHeaders = [
          { label: "Jurisdiction", field: "jurisdiction" },
          { label: "Has State DNC List", field: "hasStateDNCList" },
          { label: "List Size", field: "listSize" },
          { label: "DMA", field: "dma" },
          { label: "List Update Freq.", field: "listUpdateFreq" },
          { label: "Time To Implement", field: "timeToImplement" },
          { label: "DNC List Fee", field: "dncListFee" },
          { label: "DNC List Bond", field: "dncListBond" },
          { label: "Additional Fees", field: "additionalFees" },
          { label: "Contact", field: "contact" },
          { label: "Citations", field: "citations" },
        ];
        setHideTableHeader(false);
        setShowNPOHeader(false);
        setSelectedTopicTitle("Do Not Call List");
        return columnHeaders;
      case "LegalTimeRestrictionSummaries":
      case "LegalTimeRestrictionSummariesV2":
        columnHeaders = [
          { label: "Jurisdiction", field: "jurisdiction" },
          { label: "Weekday", field: "weekday" },
          { label: "Saturday", field: "saturday" },
          { label: "Sunday", field: "sunday" },
          { label: "Holiday", field: "holiday" },
          { label: "Citation", field: "citation" },
        ];
        setHideTableHeader(false);
        setShowNPOHeader(false);
        setSelectedTopicTitle("Time Restriction");
        return columnHeaders;
      case "LegalHolidaySummaries":
        columnHeaders = [
          { label: "Jurisdiction", field: "jurisdiction" },
          { label: "Holiday", field: "info" },
          { label: "Additional Notes", field: "notes" },
          { label: "Citation (if applicable)", field: "citations" },
        ];
        setShowNPOHeader(false);
        setSelectedTopicTitle("Holiday Restrictions");
        setHideTableHeader(false);
        return columnHeaders;
      case "LegalNpoExemptionSummaries":
        columnHeaders = [
          { label: "", field: "jurisdiction" },
          {
            label: "By employee/volunteer",
            field: "byEmployeeVolunteerDonation",
          },
          {
            label: "By outsourced call center",
            field: "byOutSourcedCallCenterDonation",
          },
          {
            label: "By employee/volunteer",
            field: "byEmployeeVolunteerGoodService",
          },
          {
            label: "By outsourced call center",
            field: "byOutSourcedCallCenterGoodService",
          },
          { label: "", field: "citation" },
        ];
        setSelectedTopicTitle("Is NPO campaign EXEMPT from the DNC list?");
        setShowNPOHeader(true);
        setHideTableHeader(false);
        return columnHeaders;
      case "LegalDncEbrPermSummaries":
        columnHeaders = [
          { label: "Jurisdiction", field: "jurisdiction" },
          { label: "Existing Customer", field: "existingCustomer" },
          {
            label: "Transaction EBR (Former Customer)",
            field: "transactionEBR",
          },
          { label: "Inquiry EBR (Inquiring Consumer)", field: "inquiryEBR" },
          { label: "Permission (Written or otherwise)", field: "permission" },
          { label: "Citations", field: "citations" },
        ];
        setHideTableHeader(false);
        setShowNPOHeader(false);
        setSelectedTopicTitle("DNC Exemptions for EBR and Permission");
        return columnHeaders;
      case "LegalAdadSummaries":
        columnHeaders = [
          { label: "Jurisdiction", field: "jurisdiction" },
          {
            label: "State-specific Restrictions",
            field: "stateSpecipicRestrictions",
          },
          { label: "Citations", field: "citations" },
        ];
        setHideTableHeader(false);
        setShowNPOHeader(false);
        setSelectedTopicTitle(
          "Prerecorded Calls for Telemarketing Purposes w/ Express Written Consent",
        );
        return columnHeaders;
      case "LegalCallMonitoringSummaries":
        columnHeaders = [
          { label: "Jurisdiction", field: "jurisdiction" },
          {
            label: "Parties",
            field: "parties",
          },
          { label: "Citations", field: "citations" },
        ];
        setHideTableHeader(false);
        setShowNPOHeader(false);
        setSelectedTopicTitle("Call Monitoring");
        return columnHeaders;
      case "LegalCitationSummaries":
        columnHeaders = [
          { label: "Jurisdiction", field: "jurisdiction" },
          { label: "Registration", field: "registration" },
          { label: "Do Not Call", field: "doNotCall" },
          { label: "ID Disclosure", field: "idDisclosure" },
          { label: "Contract Cancellation", field: "contractCancellation" },
          { label: "Permission to Continue", field: "permissionToContinue" },
          { label: "No Rebuttal", field: "noRebuttal" },
        ];
        setHideTableHeader(false);
        setShowNPOHeader(false);
        setSelectedTopicTitle("Citations Reference Chart");
        return columnHeaders;
      default:
        return columnHeaders;
    }
  }, []);

  const handleSelectByRegion = useCallback(
    async (currentSelectRegions: { state: string; stateName: string }[]) => {
      const states = currentSelectRegions.map((region) => region.state);
      const statesParam = states.join(", ");
      console.log("statesParam", statesParam);
      if (selectedTopicView === "") {
        return;
      }
      if (selectedTopicView && statesParam.length > 0) {
        setIsLoading(true);
        try {
          const apiUrl = `ComplianceGuide/GetRegulations?topic=${selectedTopicView}&region=${statesParam}&includePreviousYearHoliday=${isCheckedIncludePreviousYearHolidays}`;
          const response = await callApi(apiUrl, HttpMethod.GET);
          console.log(response);
          setTableHeader(getColumnHeaders(selectedTopicView));
          setTableData(response);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error(
            "Error fetching GetRegulations by topic, region:",
            error,
          );
        }
      } else {
        setTableData([]);
      }
    },
    [getColumnHeaders, isCheckedIncludePreviousYearHolidays, selectedTopicView],
  );

  const handleSelectByTopic = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const topic = e.target.value;
    setSelectedTopicView(topic);
    setSelectedRegions(regions);
    setIsCheckedIncludePreviousYearHolidays(false);
    setIsLoading(true);

    try {
      const apiUrl = `ComplianceGuide/GetRegulations?topic=${topic}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      console.log(response);
      setTableHeader(getColumnHeaders(topic));
      setTableData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching GetRegulations by topic:", error);
    }
  };
  const handleIncludePreviousYearHolidayChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = e.target.checked;
    setIsCheckedIncludePreviousYearHolidays(isChecked);
    const topic = "LegalHolidaySummaries";
    const states = selectedRegions.map((region) => region.state);
    const statesParam = states.join(", ");
    setSelectedTopicView(topic);
    setIsLoading(true);

    try {
      const apiUrl = `ComplianceGuide/GetRegulations?topic=${selectedTopicView}&region=${statesParam}&includePreviousYearHoliday=${isChecked}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      console.log(response);
      setTableHeader(getColumnHeaders(topic));
      setTableData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error handleIncludePreviousYearHolidayChange:", error);
    }
  };
  //GetTopics, GetRegions
  useEffect(() => {
    const getTopics = async () => {
      setIsLoading(true);
      try {
        const response = await callApi(
          "ComplianceGuide/GetTopics",
          HttpMethod.GET,
        );
        console.log(response);
        setChartOptions([{ label: "", value: "" }, ...response]);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching GetTopics:", error);
      }
    };
    const getRegions = async () => {
      try {
        const regions: Region[] = [
          ...regionsFederalData.map((region) => ({
            state: region.abbrev,
            stateName: region.name,
          })),
          ...regionsUSAData.map((region) => ({
            state: region.abbrev,
            stateName: region.name,
          })),
        ];
        setRegions(regions);
      } catch (error) {
        console.error("Error fetching GetRegions:", error);
      }
    };
    getTopics();
    getRegions();
  }, []);
  const { setHeading, setTagline } = useLayoutContext();
  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("Regulatory Charts");
      setTagline("Analyze and compare compliance requirements state by state.");
    }
  }, [setHeading, setTagline]);

  return (
    <>
      <div className="row w-100">
        <div className="compliance-intro col">
          <div className="compliance-card-container">
            <div className="row">
              <div className="col-sm-6">
                <div className="compliance-card h-100 w-100">
                  <div className="card-block">
                    <h4 className="topic-options">View by Topic</h4>
                    <div className="option-select-chart">
                      <div className="panel-body">
                        <div>
                          <select
                            size={chartOptions.length}
                            style={{
                              border: 0,
                              height: 250,
                            }}
                            className="form-select"
                            onChange={(e) => handleSelectByTopic(e)}
                            title="Select a topic"
                          >
                            {chartOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                className={styles1.chartOption}
                                style={{
                                  display:
                                    option.label === "" ? "none" : "block",
                                }}
                                // className={`${
                                //   selectedTopicView === option.value
                                //     ? "active"
                                //     : "noactive"
                                // }`}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 states-tablet">
                <div className="compliance-card h-100 w-100">
                  <div className="card-block">
                    <h4 className="topic-options">View by State or Region</h4>
                    <div
                      className={styles["option-select-box-style-second-div"]}
                    >
                      <State
                        onStatesChange={setSelectedRegions}
                        selectedStates={selectedRegions}
                        initialFetchBehavior="all"
                        styleStateWrapper={styles["option-select-boxes"]}
                        callback={handleSelectByRegion}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />
          </div>
        </div>
      </div>
      <div className="row">
        {selectedTopicView === "LegalHolidaySummaries" && (
          <div className="row">
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <div className="d-flex justify-content-end">
                <input
                  id="includePreviousYearHolidays"
                  type="checkbox"
                  className="me-1"
                  checked={isCheckedIncludePreviousYearHolidays}
                  onChange={handleIncludePreviousYearHolidayChange}
                />{" "}
                <label htmlFor="includePreviousYearHolidays">
                  Include Holidays One Year Prior
                </label>
              </div>
            </div>
          </div>
        )}
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <h5 style={{ textAlign: "center" }}>{selectedTopicTitle}</h5>
        </div>
        <div className="col-md-4"></div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {isLoading && (
            <div className="loader-container">
              <Grid
                height="40"
                width="40"
                color="#0eb0e5"
                ariaLabel="grid-loading"
                radius="12.5"
                wrapperStyle={{}}
                wrapperClass=""
                visible={isLoading}
              />
            </div>
          )}
          <Table
            topic={selectedTopicView}
            columns={tableHeader}
            data={tableData}
            hideTableHeader={hideTableHeader}
            showNPOHeader={showNPOHeader}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
