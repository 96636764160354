import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatCardDateTime } from "src/utils/formatUtils";
import { HttpMethod, callApi } from "src/services/apiService";
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";

interface Ticket {
  id: number;
  subject: string;
  status: string;
  updatedAt: string;
  createdAt?: string;
  lastCommentAt?: string;
}

const ListTickets = () => {
  const [pageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [sort, setSort] = useState<SortDescriptor[]>([
    { field: "id", dir: "desc" },
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTickets();
    // eslint-disable-next-line
  }, [showAll]);

  const fetchTickets = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `help/my-tickets?page=1&pageSize=1000&all=${showAll}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response) {
        setTickets(response.tickets);
        setTotalCount(response.tickets.length);
        setPage(1);
      }
    } catch (error) {
      console.error("There has been a problem fetching tickets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const pagedAndSortedData = orderBy(tickets, sort).slice(
    (page - 1) * pageSize,
    page * pageSize,
  );

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
    setPage(1);
  };

  const handleSortChange = (e: GridSortChangeEvent) => {
    setSort(e.sort);
  };

  const handlePageChange = (e: GridPageChangeEvent) => {
    setPage(e.page.skip / e.page.take + 1);
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>My Tickets</h1>
      </div>

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <Link to="/help/new" className="btn btn-primary">
          Create New Ticket
        </Link>

        <label className="form-check-label mb-0">
          Show all tickets
          <input
            type="checkbox"
            className="form-check-input ms-2"
            checked={showAll}
            onChange={handleToggleShowAll}
          />
        </label>
      </div>

      {tickets.length === 0 ? (
        <p>No tickets found.</p>
      ) : (
        <div className="position-relative">
          <Grid
            data={pagedAndSortedData}
            pageable
            sortable
            skip={(page - 1) * pageSize}
            take={pageSize}
            total={totalCount}
            sort={sort}
            onSortChange={handleSortChange}
            onPageChange={handlePageChange}
            onRowClick={(e) => navigate(`/help/ticket/${e.dataItem.id}`)}
          >
            <GridColumn field="id" title="ID" width="70px" />
            <GridColumn
              field="subject"
              title="Subject"
              cell={(props) => (
                <td className="text-primary text-decoration-underline">
                  {props.dataItem.subject}
                </td>
              )}
            />
            <GridColumn field="status" title="Status" />
            <GridColumn
              field="updatedAt"
              title="Last Updated"
              cell={(props) => (
                <td>{formatCardDateTime(props.dataItem.updatedAt)}</td>
              )}
            />
          </Grid>

          {isLoading && (
            <div
              className="position-absolute top-50 start-50 translate-middle bg-white bg-opacity-75 p-3 rounded"
              style={{ zIndex: 10 }}
            >
              <div className="spinner-border text-primary" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ListTickets;
