import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import Warning from "src/components/Warning";
import SaveInProgress from "src/components/SaveInProgress";
import {
  ICrossAccounts,
  ICrossAccountsAgencyAccount,
} from "src/interfaces/Admin/ICrossAccount";
import CrossAccountsAccess from "./CrossAccountsAccess";
import Button from "react-bootstrap/Button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SafeHtml from "src/components/SafeHtml";

const CrossAccounts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [formData, setFormData] = useState<ICrossAccounts>();
  const [originalData, setOriginalData] = useState<ICrossAccounts | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [grantAgencyCode, setGrantAgencyCode] = useState<string>("");
  const [selectedAgency, setSelectedAgency] =
    useState<ICrossAccountsAgencyAccount>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<ICrossAccounts>({ mode: "onChange" });

  const fetchCrossAccounts = useCallback(async () => {
    setIsLoading(true);
    try {
      setWarningMessage("");
      const apiUrl = "admin/crossaccounts";
      const response = await callApi(apiUrl, HttpMethod.GET);
      setFormData(response);
      setOriginalData(response);
      reset(response);
    } catch (error) {
      console.error("Failed to fetch cross accounts:", error);
      setWarningMessage("Failed to fetch cross accounts.");
    } finally {
      setIsLoading(false);
    }
  }, [reset]);

  useEffect(() => {
    fetchCrossAccounts();
  }, [fetchCrossAccounts]);

  const onCrossAccountSubmit = async (formData: ICrossAccounts) => {
    setSaveInProgress(true);
    try {
      setWarningMessage("");
      const apiUrl = `admin/crossaccounts/Save`;
      const method = HttpMethod.POST;
      const body = formData.crossAccounts;

      await callApi(apiUrl, method, body);
      console.log("Settings saved successfully");
    } catch (error) {
      console.error("Failed to save settings:", error);
      setWarningMessage("Failed to save settings");
    }
    setSaveInProgress(false);
  };

  const handleReset = () => {
    if (originalData) reset(originalData);
  };

  if (isLoading) {
    return <Loading />;
  }

  const handleRevokeButtonClick = async (agencyAcctId: string) => {
    setIsLoading(true);
    try {
      setWarningMessage("");
      const apiUrl = `admin/crossaccounts/revoke/${agencyAcctId}`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response.message) {
        if (response.message.toLowerCase() === "success") {
          fetchCrossAccounts();
        } else {
          setWarningMessage(response.message);
        }
      }
    } catch (error) {
      console.error("Failed to revoke access:", error);
      setWarningMessage("Failed to revoke access.");
    } finally {
      setIsLoading(false);
    }
  };
  const handleGrantButtonClick = async () => {
    setIsLoading(true);
    try {
      setWarningMessage("");
      const apiUrl = `admin/crossaccounts/grant/${grantAgencyCode}`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response.message) {
        if (response.message.toLowerCase() === "success") {
          fetchCrossAccounts();
        } else {
          setWarningMessage(response.message);
        }
      }
    } catch (error) {
      console.error("Failed to grant access:", error);
      setWarningMessage("Failed to grant access.");
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = (agency: ICrossAccountsAgencyAccount) => {
    setSelectedAgency(agency);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="pb-5">
        <h6>Grant Access to My Projects to Other Accounts</h6>
        {formData && (
          <>
            <p>
              <SafeHtml html={formData?.content || ""} />
            </p>
            <form
              onSubmit={handleSubmit(onCrossAccountSubmit)}
              className="grid grid-cols-2 gap-4"
            >
              <div>
                {warningMessage && <Warning message={warningMessage} />}
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Account Code</th>
                      <th>Agency Name</th>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-billing">
                            Scrubbing is billed under.
                          </Tooltip>
                        }
                      >
                        <th>Billing</th>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-create-new-projects">
                            Allow Agency to create new projects in my Account.
                          </Tooltip>
                        }
                      >
                        <th>Create New Projects</th>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-access-new-projects">
                            Automatically grant access to new projects I create.
                          </Tooltip>
                        }
                      >
                        <th>Access to New Projects</th>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-access-new-projects">
                            Allow Agency to view Master Project's FTC info on
                            projects set to use Master Projects's OrgId.
                          </Tooltip>
                        }
                      >
                        <th>Access To FTC Info</th>
                      </OverlayTrigger>
                      <th>Access</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.crossAccounts.map((account, index) => (
                      <tr key={account.id}>
                        <td className="text-center align-middle">
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() =>
                              handleRevokeButtonClick(account.agencyAcctId)
                            }
                          >
                            <i className="bi bi-x me-2"></i>Revoke
                          </Button>
                          <input
                            type="hidden"
                            {...register(`crossAccounts.${index}.id`)}
                            value={account.id}
                          />
                          <input
                            type="hidden"
                            {...register(`crossAccounts.${index}.agencyAcctId`)}
                            value={account.agencyAcctId}
                          />
                        </td>
                        <td className="text-center align-middle">
                          {account.agencyAcctId}
                        </td>
                        <td className="text-center align-middle">
                          {account.agencyName}
                        </td>
                        <td className="text-center align-middle">
                          <select
                            {...register(
                              `crossAccounts.${index}.usageBilledTo`,
                            )}
                            defaultValue={account.usageBilledTo}
                          >
                            <option value={account.myAcctId}>My Account</option>
                            <option value={account.agencyAcctId}>
                              Agency Account
                            </option>
                          </select>
                        </td>
                        <td className="text-center align-middle">
                          <input type="checkbox" />
                        </td>
                        <td className="text-center align-middle">
                          <input
                            type="checkbox"
                            {...register(
                              `crossAccounts.${index}.agencyGetsAccessToNewProj`,
                            )}
                            defaultChecked={account.agencyGetsAccessToNewProj}
                          />
                        </td>
                        <td className="text-center align-middle">
                          <input
                            type="checkbox"
                            {...register(
                              `crossAccounts.${index}.agencyGetsAccessToFtcInfo`,
                            )}
                            defaultChecked={account.agencyGetsAccessToFtcInfo}
                          />
                        </td>
                        <td className="text-center align-middle">
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            onClick={() => openModal(account)}
                          >
                            Configure Access
                          </Button>
                        </td>
                      </tr>
                    ))}

                    <tr key="NewAcct">
                      <td className="text-center align-middle">
                        <Button
                          variant="outline-success"
                          size="sm"
                          onClick={() => handleGrantButtonClick()}
                        >
                          <i className="bi bi-check2 me-2"></i>Grant
                        </Button>
                      </td>
                      <td className="text-center align-middle">
                        <input
                          type="text"
                          className="form-control"
                          value={grantAgencyCode}
                          size={5}
                          max-width={5}
                          placeholder="Enter Agency Code..."
                          onChange={(e) => setGrantAgencyCode(e.target.value)}
                        />
                      </td>
                      <td colSpan={6}></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-span-2 mt-2">
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  disabled={!isDirty}
                  onClick={() => handleSubmit(onCrossAccountSubmit)()}
                >
                  <i className="bi bi-floppy me-2"></i>Update Settings
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <SaveInProgress isVisible={saveInProgress} />
              </div>
            </form>
            {/* Modal for Configuring Access */}
            {isModalOpen && selectedAgency && (
              <CrossAccountsAccess
                agency={selectedAgency}
                onClose={() => setIsModalOpen(false)}
              />
            )}
          </>
        )}
      </div>
      <div className="d-none">
        <pre>{JSON.stringify(formData, null, 2)}</pre>
      </div>
    </>
  );
};

export default CrossAccounts;
