import { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "src/app/hooks";
import { selectAuth, setAuthInfo } from "src/features/authSlice";
import { HttpMethod, callApi } from "src/services/apiService";
import ThemeSelector from "./ThemeSelector";
import Cookies from "js-cookie";
import Styles from "./Header.module.css";
import ProfileDropdown from "./ProfileDropdown";
import SVGForPage from "./SVGForPage";
import Loading from "src/components/Loading";
import HelpButton from "./HelpButton";
import { IMenu, IMenuItem } from "src/interfaces/IHeader";
import { getLogo } from "src/services/logoService";
import { useLocation } from "react-router-dom";
import { useUploadListContext } from "src/contexts/UploadListContext";
import { ITreeNode } from "src/interfaces/ITreeNode";

type DropdownItem = {
  label: string;
  href: string;
  isActive?: boolean;
};

type NavDropdown = {
  label: string;
  caption: string;
  href?: string;
  items: DropdownItem[];
};

const underlineSectionIfActive = (section: string) => {
  const sectionToPathMap: Record<string, string> = {
    main: "/main",
    accounts: "/admin",
    trustcall: "/t/trustcall",
    users: "/t/users",
    reports: "/reports",
    logocall: "/logocall",
    "compliance guide": "/compliance-guide",
    "litigator research": "/t/litigator-research",
  };

  const path = sectionToPathMap[section.toLowerCase()];
  return window.location.pathname.startsWith(path)
    ? "underline-navbar-item"
    : "";
};

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isSysadmin, acctId } = useAppSelector(selectAuth);
  const navigate = useNavigate();

  const [newAcctId, setNewAcctId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState("");
  const [showSalesReport, setshowSalesReport] = useState(false);
  const [filteredDropdowns, setFilteredDropdowns] = useState<NavDropdown[]>([]);
  const location = useLocation();
  const { setProjectId, setCampaignId, setTreeData } = useUploadListContext();

  const fetchMenuItems = async () => {
    try {
      const apiUrl = `Header`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (!response) {
        console.error("Failed to fetch header data");
        return;
      }

      const defaultLogo = "/images/DNC-Logo-White.svg";
      const fetchedLogo = getLogo(response.logo);
      if (fetchedLogo) {
        setLogo(fetchedLogo);
      } else {
        setLogo(defaultLogo);
      }

      const updatedDropdowns: NavDropdown[] = [];

      for (const menuKey of Object.keys(response.menu)) {
        const menuItems = response.menu[menuKey as keyof IMenu];
        if (menuItems.length > 0) {
          updatedDropdowns.push({
            label: response.captions[menuKey] || menuKey,
            caption: response.captions[menuKey], // Fetch the caption using the menu key
            items: menuItems.map((item: IMenuItem) => ({
              label: item.key,
              href: `${item.value}`,
            })),
          });
        }
      }

      // Update the state to hold the filtered dropdown items
      setFilteredDropdowns(updatedDropdowns);

      // Set the showSalesReport flag
      setshowSalesReport(response.showSalesReport);
    } catch (error) {
      console.error("Failed to fetch header data:", error);
    }
  };

  useEffect(() => {
    fetchMenuItems();
  }, [acctId]);

  /* 
    Collapse dropdown menu if open and the user clicks outside
    the browser. Especially useful with the iframes we have. If we don't have
    this you can't click outside the iframe to close the dropdown.
    */
  useEffect(() => {
    const collapseDropdownMenuIfOpen = () => {
      const elements = document.querySelectorAll(".show.dropdown-menu");
      elements.forEach((element) => {
        element.classList.remove("show");
      });
    };
    window.addEventListener("blur", collapseDropdownMenuIfOpen);
    return () => {
      window.removeEventListener("blur", collapseDropdownMenuIfOpen);
    };
  }, []);

  const handleAcctChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewAcctId(e.target.value);
  };

  const handleAcctIdChange = async () => {
    setIsLoading(true);
    try {
      const upperCaseAcctId = newAcctId.toUpperCase();
      const apiUrl = `Users/ChangeAccount?newAcctId=${upperCaseAcctId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response.type === "FAILED") {
        console.log("Failed to change account");
        navigate("/error", { state: { message: response.message } });
      } else {
        if (response.acctId === upperCaseAcctId) {
          // Below line needed to clear tree data or GetSessionRows API call intermittently
          // made with wrong campaignId immediately after acct change
          setTreeData([] as ITreeNode[]);

          setCampaignId("");
          setProjectId("");

          dispatch(
            setAuthInfo({
              acctId: response.acctId,
              acctName: response.acctName,
              username: response.username,
              fullName: response.fullName,
              quickScrub: response.quickScrub,
              role: response.role,
              isSysadmin: response.isSysadmin,
              loginId: response.loginId,
              csrfToken: response.csrfToken,
              hasTrainingFeature: response.hasTrainingFeature,
              homePage: response.homePage,
              acctMessage: response.acctMessage,
            }),
          );
          Cookies.set("acctId", upperCaseAcctId, {
            domain: ".dncscrub.com",
            path: "/",
          });
          // Just let the server send the loginId cookie unless
          // on dev where the server may be at domain https://localhost.
          // See if this eliminates intermittent bug where user is
          // logged into React app but can't use Tcl app
          // - JH 2025-02-15
          const shouldSetLoginIdCookie =
            process.env.NODE_ENV === "development" &&
            import.meta.env.VITE_APP_API_URL?.includes("localhost");
          if (shouldSetLoginIdCookie) {
            Cookies.set("loginId", response.loginId, {
              domain: ".dncscrub.com",
              path: "/",
            });
          }

          console.log("Account changed to " + response.acctName);
          fetchMenuItems();
          navigate("/home");
        } else {
          console.log("Failed to change account");
        }
      }
    } catch (error) {
      console.log("Failed to change account");
    } finally {
      setIsLoading(false);
      setNewAcctId("");
    }
  };
  /* Collapse the top dropdown navbar when a link is clicked. Without this on a mobile
  screen the dropdown navbar stays in place */
  const collapseNavbarOnClick = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (
      (target.tagName === "A" &&
        (target.classList.contains("dropdown-item") ||
          target.getAttribute("data-bs-toggle") === "")) ||
      target.innerText === "TrustCall" ||
      target.innerText === "Users"
    ) {
      const navbar = document.getElementById("top-navbar");
      if (navbar) {
        navbar.classList.remove("show");
      }
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <nav className="navbar navbar-expand-md navbar-background">
      <div className="container-fluid">
        {/* Only show the campaign treeview toggle button if in the
        main section  */}
        {location.pathname.includes("/main/") && (
          <button
            className={`${Styles["navbar-toggler-left-sidebar"]}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#left-hand-navbar"
            title="Toggle campaign menu"
          >
            <span
              className={`navbar-toggler-icon ${Styles["navbar-toggler-icon-top-menu"]}`}
            ></span>
          </button>
        )}

        <Link className="" to="/home">
          {logo && (
            <img alt="DNCScrub logo" className={Styles["logo"]} src={logo} />
          )}
        </Link>

        <button
          className={`navbar-toggler ${Styles["navbar-toggler-top-menu"]}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#top-navbar"
          title="Toggle navigation menu"
        >
          <span
            className={`navbar-toggler-icon ${Styles["navbar-toggler-icon-top-menu"]}`}
          ></span>
        </button>

        <div
          className={`collapse navbar-collapse ${Styles["top-navbar-container"]}`}
          id="top-navbar"
          onClick={collapseNavbarOnClick}
        >
          <ul className="navbar-nav me-auto nav-pills justify-content-start flex-grow-1">
            {/* Iterate through all the navbar items to build navbar */}
            {filteredDropdowns.map((dropdown, index) => (
              <li key={index} className="nav-item dropdown">
                <Link
                  aria-current="page"
                  aria-expanded="false"
                  className={`nav-link dropdown-toggle`}
                  data-bs-toggle={dropdown.items.length > 1 ? "dropdown" : ""}
                  to={
                    dropdown.items.length === 1 ? dropdown.items[0].href : "#"
                  }
                  role="button"
                  onClick={
                    dropdown.items.length > 1
                      ? (e) => e.preventDefault()
                      : undefined
                  }
                >
                  <span
                    className={`${underlineSectionIfActive(dropdown.label)}`}
                  >
                    {dropdown.label === "Compliance Guide" ? (
                      <span>
                        Compliance
                        <span className="d-none d-xl-inline"> Guide</span>
                      </span>
                    ) : (
                      dropdown.label
                    )}
                  </span>
                </Link>
                {dropdown.items.length > 1 && (
                  <ul className="dropdown-menu">
                    {dropdown.items.map((item, i) => (
                      <React.Fragment key={i}>
                        <li className="nav-item">
                          <Link className="dropdown-item" to={item.href}>
                            <SVGForPage
                              pageKeyword={item.href.split("/").pop()}
                            />
                            {item.label}
                          </Link>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            <a
              className="nav-link d-none d-md-block"
              role="button"
              href="https://classic.dncscrub.com/app/"
            >
              <span className="fs-0">
                <i className="bi bi-box-arrow-right"></i> Classic App
              </span>
            </a>
          </ul>

          {/* Start right-hand part of navbar */}
          <div className="d-flex align-items-center gap-0 gap-lg-2 navbar-nav justify-content-end flex-nowrap">
            <HelpButton />
            <ThemeSelector />
            {/* Start sysadmin acct changer */}
            <div>
              {isSysadmin && (
                <>
                  <div className={`d-flex`}>
                    <input
                      type="text"
                      name="acctId"
                      style={{ width: 60 }}
                      className={`${Styles["sysadmin-acctid-switch-box"]}`}
                      onChange={handleAcctChange}
                      value={newAcctId}
                      placeholder="Acct Id"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAcctIdChange();
                        }
                      }}
                    />
                    <button
                      className="text-white ps-2 pe-2 btn btn-secondary btn-sm rounded-0"
                      onClick={handleAcctIdChange}
                    >
                      Go
                    </button>
                  </div>
                  <a
                    href={`https://classic.dncscrub.com/app/sysadmin/accounts.adp?acctId=${acctId}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={`dropdown-item ${Styles["link-to-sysadmin-current-acct"]}`}
                  >
                    ({acctId})
                  </a>
                </>
              )}
            </div>
            {/* End sysadmin acct changer */}
            <div className="d-none d-lg-block">
              {isSysadmin && (
                <>
                  {/* For now lets open sysadmin in a new window. This is what our support team is used to
              Sysadmin can also be embedded with url /t/sysadmin but unclear if we will use */}
                  <a
                    href="https://classic.dncscrub.com/app/sysadmin/"
                    target="_blank"
                    className="dropdown-item d-flex gap-1"
                    rel="noreferrer noopener"
                  >
                    <i className={`bi bi-gear`}></i>
                    <span
                      className={`d-none ${Styles["d-show-sysadmin-link-title-lg"]} fw-semibold`}
                    >
                      Sysadmin
                    </span>
                  </a>
                </>
              )}

              {(isSysadmin || showSalesReport) && (
                <Link
                  to="/t/sales-production-report"
                  className="dropdown-item d-flex gap-1"
                >
                  <i className={`bi bi-file-earmark-spreadsheet`}></i>
                  <span
                    className={`d-none ${Styles["d-show-sysadmin-link-title-lg"]} fw-semibold`}
                  >
                    Sales Report
                  </span>
                </Link>
              )}
            </div>
            <ProfileDropdown showSalesReport={showSalesReport} />
          </div>
          {/* End right-hand part of navbar */}
        </div>
      </div>
    </nav>
  );
};

export default Header;
